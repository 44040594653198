import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';

import { useBreakpoint } from '@jernia/shared/hooks/breakpoint-hooks';

export type DrawerProps = {
  position?: 'left' | 'right';
  closeButton?: boolean;
  isOpen: boolean;
  onRequestClose?: () => void;
  onAfterClose?: () => void;
  className?: string;
  overlayClassName?: string;
  children: React.ReactNode;
  variant?: 'default' | 'header';
  headerId?: string;
  scrollToTop?: boolean;
  offsetTop?: number;
};

const updateContentPosition = (
  headerId: string,
  node: HTMLDivElement,
  isDesktop: boolean
) => {
  const header = document.getElementById(headerId);
  if (header && isDesktop) {
    const headerHeight = header.clientHeight;

    node.setAttribute('style', `max-height: calc(100% - ${headerHeight}px)`);
  } else {
    node.setAttribute('style', `max-height: 100%`);
  }
};

export const Drawer = ({
  position = 'left',
  children,
  isOpen,
  className,
  onRequestClose,
  variant = 'default',
  scrollToTop = false,
  headerId,
  offsetTop = 0,
}: DrawerProps) => {
  const drawerRef = useRef<ReactModal>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isLg = useBreakpoint('lg');

  const underHeader = variant === 'header' && !!headerId;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (isOpen) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (!underHeader) return;
    const node = contentRef.current;
    if (!node) return;

    updateContentPosition(headerId, node, isLg);
  }, [isLg, underHeader]);

  return (
    <>
      <ReactModal
        contentRef={(node) => {
          contentRef.current = node;

          if (!underHeader || !isLg || !node) return;
          updateContentPosition(headerId, node, true);
          if (scrollToTop) {
            window.scrollTo(0, offsetTop);
          }
        }}
        ref={drawerRef}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        parentSelector={() => {
          return document.getElementById('next-drawers') ?? document.body;
        }}
        overlayClassName={{
          base: classNames(
            'bg-neutral-800/0 fixed top-0 left-0 w-full h-full transition-all duration-300 flex items-center z-50',
            position === 'right' ? 'justify-end' : 'justify-start'
          ),
          afterOpen: 'bg-neutral-800/50',
          beforeClose: '!bg-neutral-800/0',
        }}
        className={{
          base: classNames(
            'fixed h-full transform overflow-hidden bg-white shadow-xl outline-none flex flex-col transition-transform duration-300 ease-in-out',
            {
              'translate-x-full': position === 'right',
              '-translate-x-full': position !== 'right',
              'w-[85vw] sm:max-w-sm max-h-full': variant === 'default',
              'w-full lg:w-auto': variant !== 'default',
              '4xl:pr-[calc((100vw-1376px)/2-32px)] ':
                variant !== 'default' && position === 'right',
              '4xl:pl-[calc((100vw-1376px)/2-32px)] ':
                variant !== 'default' && position === 'left',
              'bottom-0': underHeader && isLg,
              [`top-[${offsetTop}px]`]: offsetTop > 0,
            },
            className // for any additional classes passed via the className prop
          ),
          afterOpen: isOpen ? '!translate-x-0' : '',
          beforeClose: `${
            position === 'right' ? 'translate-x-full' : '-translate-x-full'
          }`,
        }}
        closeTimeoutMS={300}
      >
        <div
          className={classNames('overflow-y-auto', {
            'h-[100dvh] w-full overflow-y-auto': variant === 'default',
            'h-full': variant !== 'default',
          })}
          style={{
            maxHeight: offsetTop > 0 ? `calc(100vh - ${offsetTop}px)` : '100vh',
          }}
        >
          {/**
           * Hack to get animation to properly work in Safari. I have no clue
           * why this works, but without this it won't transition in, only
           * transition out on close. ¯\_(ツ)_/¯
           */}
          <motion.div
            aria-hidden
            variants={{
              active: {
                display: 'block',
              },
            }}
            animate={'active'}
          />
          {children}
        </div>
      </ReactModal>
      {/* <ScrollLock isActive={isOpen} /> */}
    </>
  );
};

export default Drawer;
