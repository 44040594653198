import algoliasearch from 'algoliasearch/lite';
import React from 'react';
import { InstantSearch, type InstantSearchProps } from 'react-instantsearch';

import {
  API_KEY,
  APP_ID,
  PRODUCT_INDEX_NAME,
} from '@jernia/shared/lib/algolia';

const searchClient = algoliasearch(APP_ID, API_KEY);

type InstantSearchContainerProps = Omit<
  InstantSearchProps,
  'searchClient' | 'routing'
> & {
  indexName?: string;
  routing?: any | undefined;
};

export function InstantSearchContainer({
  indexName = PRODUCT_INDEX_NAME,
  children,
  routing,
}: InstantSearchContainerProps) {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      routing={routing}
    >
      {children}
    </InstantSearch>
  );
}
