export type Facets = Record<string, string[]>;

export function buildQueryString({
  query = '',
  sort = 'relevance',
  page = 0,
  rows = 20,
  facets = {},
  codes,
}: {
  query?: string;
  codes?: string[];
  sort?: string;
  page?: number;
  rows?: number;
  facets?: Facets;
}) {
  const facetQuery = Object.entries(facets)
    .filter(([, values]) => values.length > 0)
    .flatMap(([code, values]) => {
      return values.map((value) => `${code}:${value}`);
    })
    .join(':');

  let q = `page,${page},,rows,${rows}`;
  if (Array.isArray(codes)) {
    q = `code,${codes.join(',')},${q}`;
  }

  return Object.entries({
    search: `${query}:${sort}:${facetQuery}`,
    q,
  })
    .map(([key, value]) => {
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');
}
