import {
  Breadcrumb,
  Breadcrumbs,
} from '@jernia/shared/components/breadcrumbs/breadcrumbs';
import { ContentContainer } from '@jernia/shared/components/content-container/content-container';
import { H1 } from '@jernia/shared/components/typography/heading/heading';

import { SearchBox } from '../algolia/search-box';

export default function Header({
  title,
  breadcrumbs,
  showSearchField,
}: {
  title?: string;
  breadcrumbs?: Breadcrumb[];
  showSearchField?: boolean;
}) {
  return (
    <header className="bg-white py-8">
      <ContentContainer>
        {breadcrumbs ? (
          <div className="border-b border-neutral-500 pb-4">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        ) : null}

        {title ? (
          <div className="mt-4 flex flex-col sm:flex-row sm:justify-between">
            <H1>{title}</H1>
          </div>
        ) : null}

        {showSearchField ? (
          <div className="mt-8">
            <SearchBox />
          </div>
        ) : null}
      </ContentContainer>
    </header>
  );
}
