import classNames from 'classnames';
import React, { ElementType } from 'react';

const __ = '1D45E01E-AF44-47C4-988A-19A94EBAF55C' as const;
type __ = typeof __;

export type PropsOf<TTag = any> = TTag extends React.ElementType
  ? React.ComponentProps<TTag>
  : never;

type PropsWeControl = 'as';

type OurProps<TTag> = {
  as?: TTag;
};

type CleanProps<
  TTag,
  TOmitableProps extends keyof any = __
> = TOmitableProps extends __
  ? Omit<PropsOf<TTag>, PropsWeControl>
  : Omit<PropsOf<TTag>, TOmitableProps | PropsWeControl>;

type Props<TTag, TOmitableProps extends keyof any = __> = CleanProps<
  TTag,
  TOmitableProps
> &
  OurProps<TTag>;

const DEFAULT_COMPONENT = 'div' as const;

export const ContentContainer = React.forwardRef(function ContentContainer<
  TTag extends ElementType = typeof DEFAULT_COMPONENT
>(
  {
    as,
    id,
    className,
    children,
    size = 'default',
  }: Props<TTag> & { size: 'default' | 'lg' },
  ref: React.Ref<HTMLDivElement>
) {
  const Component = as || DEFAULT_COMPONENT;

  return (
    <Component
      id={id}
      className={classNames(className, 'mx-auto px-4 md:px-8', {
        'xl:max-w-screen-xl': size === 'default',
        // Note need to check if this is the correct way to do this
        'max-w-1440': size === 'lg',
      })}
      ref={ref}
    >
      {children}
    </Component>
  );
});
