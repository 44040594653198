import { useRouter } from 'next/router';

export function useIsApp(appName: string): boolean {
  let isApp = false;
  const router = useRouter();

  switch (appName) {
    case 'rental':
      isApp = router?.route.includes('/rental');
      break;

    case 'ticketing':
      isApp = router?.route.includes('/tickets');
      break;

    case 'customers':
      isApp = router?.route.includes('/customers');
      break;

    case 'b2h':
      isApp =
        router?.route.includes('/b2h') ||
        router?.route.includes('/category') ||
        router?.route.includes('/cart') ||
        router?.route.includes('/product');
      break;

    default:
      break;
  }

  return isApp;
}
