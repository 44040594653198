import type { Cart } from '@jernia/shared/types/rest/cart';

export type DeliveryMode =
  | {
      kind: 'standard';
      deliveryId: string;
    }
  | {
      kind: 'droppoint';
      deliveryId: string;
      droppoint: string;
    }
  | {
      kind: 'deliveryservice';
      deliveryId: string;
      serviceCodes: string[];
    };

export function getCartId(cart?: Cart | null) {
  if (!cart) {
    return null;
  }

  return cart.user.uid == 'anonymous' || cart.user.name === 'guest'
    ? cart.guid
    : cart.code;
}
