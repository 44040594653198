import classNames from 'classnames';
import React from 'react';

export type HeadingProps = {
  level?: HeadingLevel;
  size?: HeadingSize;
  highlight?: string;
} & React.HTMLAttributes<HTMLHeadingElement>;

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type HeadingSize =
  | 'd1'
  | 'd2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'bypass';

export function Heading({
  level = 'h1',
  size,
  highlight,
  children,
  className,
  ...props
}: HeadingProps) {
  const hSize = size ?? level;

  const headingStyles = classNames(
    'leading-hd font-semibold tracking-hd hyphens-manual mt-0',
    {
      'text-display-1-m md:text-display-1': hSize === 'd1',
      'text-display-2-m md:text-display-2': hSize === 'd2',
      'text-hd-1-m md:text-hd-1': hSize === 'h1',
      'text-hd-2-m md:text-hd-2': hSize === 'h2',
      'text-hd-3-m md:text-hd-3': hSize === 'h3',
      'text-hd-4-m md:text-hd-4': hSize === 'h4',
      'text-hd-5-m md:text-hd-5': hSize === 'h5',
      'text-hd-6-m md:text-hd-6': hSize === 'h6',
    },
    className
  );

  // Handle soft hyphens and special #content# case
  const processContent = (content: string) => {
    const parts = content.split(/(#[^#]+#)/g).filter(Boolean);
    return parts.map((part, index) => {
      if (part.startsWith('#') && part.endsWith('#')) {
        const innerContent = part.slice(1, -1);
        return (
          <span key={index} className={classNames(highlight)}>
            {innerContent}
          </span>
        );
      }
      return part.split('&shy;').map((subPart, subIndex) => (
        <React.Fragment key={`${index}-${subIndex}`}>
          {subPart}
          {subIndex < part.split('&shy;').length - 1 && '\u00AD'}
        </React.Fragment>
      ));
    });
  };

  const processedChildren =
    typeof children === 'string' ? processContent(children) : children;

  const Component = level;

  return (
    <Component className={headingStyles} {...props}>
      {processedChildren}
    </Component>
  );
}

export function Display1({ size = 'd1', ...props }: HeadingProps) {
  return (
    <Heading size={size} level="h1" className={props.className} {...props}>
      {props.children}
    </Heading>
  );
}

export function Display2({ size = 'd2', ...props }: HeadingProps) {
  return (
    <Heading size={size} level="h1" className={props.className} {...props}>
      {props.children}
    </Heading>
  );
}

export function H1({ size = 'h1', ...props }: HeadingProps) {
  return (
    <Heading size={size} level="h1" className={props.className} {...props}>
      {props.children}
    </Heading>
  );
}

export function H2({ size = 'h2', ...props }: HeadingProps) {
  return (
    <Heading size={size} level="h2" className={props.className} {...props}>
      {props.children}
    </Heading>
  );
}

export function H3({ size = 'h3', ...props }: HeadingProps) {
  return (
    <Heading size={size} level="h3" className={props.className} {...props}>
      {props.children}
    </Heading>
  );
}

export function H4({ size = 'h4', ...props }: HeadingProps) {
  return (
    <Heading size={size} level="h4" className={props.className} {...props}>
      {props.children}
    </Heading>
  );
}

export function H5({ size = 'h5', ...props }: HeadingProps) {
  return (
    <Heading size={size} level="h5" className={props.className} {...props}>
      {props.children}
    </Heading>
  );
}

export function H6({ size = 'h6', ...props }: HeadingProps) {
  return (
    <Heading size={size} level="h6" className={props.className} {...props}>
      {props.children}
    </Heading>
  );
}
