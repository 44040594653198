import { useQuery } from 'react-query';

import { useProducts } from '@jernia/shared/hooks/product';

import { Placement, RecommendedProduct, RichRelevanceResult } from './types';

type RichRelevanceQuery = {
  sessionId?: string;
  userId?: string;
  placements?: string;
  productId?: string;
  chi?: string;
  rcs?: string;
  atcid?: string;
  categoryId?: string;
};

export function useRichRelevance({
  sessionId = '',
  userId = '',
  placements = [],
  productIds = [],
  categoryIds = [],
  rcs = '',
  atcid,
}: {
  sessionId?: string;
  userId?: string;
  placements?: string[];
  productIds?: string[];
  categoryIds?: string[];
  rcs?: string;
  atcid?: string;
}) {
  const productId = productIds.join('|');

  // If there's only one category, we assume the Algonomy block is used
  // on a category page, and should pass the `productId` prop. Otherwise
  // we pass `chi` as "category hints".
  // Docs: https://cdn.richrelevance.com/online_help/rr_olh/Content/Topics_Integration/Core%20Integration%20for%20recsForPlacements%20API.htm
  const categoryId = categoryIds?.length > 1 ? undefined : categoryIds?.[0];
  const chi = categoryId ? undefined : categoryIds.join('|');

  const placementsJoined = placements.join('|');

  let queryParams: RichRelevanceQuery = {
    sessionId,
    userId,
    placements: placementsJoined,
    productId,
    chi,
    rcs,
    categoryId,
  };

  if (atcid) {
    queryParams = { ...queryParams, atcid };
  }

  const query = JSON.stringify(queryParams);

  return useQuery<unknown, unknown, RichRelevanceResult>(
    ['richrelevance', query],
    async () => {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE}/api/rich-relevance`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: query,
        }
      );

      return await response.json();
    }
  );
}

export function mapProductIdsOfPlacement(
  placement?: Placement | null
): string[] {
  if (placement && Array.isArray(placement.recommendedProducts)) {
    return placement.recommendedProducts.map((product) => product.id);
  }

  return [];
}

export function usePlacement(placement: Placement) {
  const productCodes =
    placement.recommendedProducts.map(
      (product: RecommendedProduct) => product.id
    ) ?? [];

  const products = useProducts(productCodes);

  return {
    title: placement.strategyMessage ?? '',
    rrProducts: placement.recommendedProducts ?? [],
    products: Array.isArray(products) ? products : [],
  };
}
