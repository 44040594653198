import { resolveHref } from 'next/dist/client/resolve-href';
import NextLink from 'next/link';
import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import { config } from '../lib/config';

const useConfigNextLink = config.useNextLink;

type JerniaLinkProps = React.PropsWithChildren<LinkProps> & {
  isNextLink?: boolean;
};

export function JerniaLink({
  children,
  href,
  isNextLink,
  ...rest
}: JerniaLinkProps) {
  const router = useRouter();

  const useNextLink = isNextLink ?? useConfigNextLink;

  if (useNextLink) {
    return (
      <NextLink {...rest} href={href} legacyBehavior>
        {children}
      </NextLink>
    );
  }

  const resolvedHref = resolveHref(router, href);
  const child = React.Children.only(children);

  const chilProps = {
    href: resolvedHref,
    ...rest,
  };

  return React.isValidElement(child)
    ? React.cloneElement(child, chilProps)
    : null;
}
