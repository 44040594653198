import classnames from 'classnames';
import Link from 'next/link';

export type RoundedButtonProps = {
  color?: 'primary' | 'bg-primary-100' | 'success-800' | 'grey';
  size?: 'medium' | 'small';
  className?: string;
  onClick?: () => void;
  href?: string;
  children: React.ReactNode;
  noButton?: boolean;
};

const RoundedButtonWrapper = ({
  noButton,
  href,
  ...props
}: RoundedButtonProps) => {
  if (href) {
    return (
      <Link href={href} {...props}>
        {props.children}
      </Link>
    );
  } else if (noButton) {
    return <span {...props}>{props.children}</span>;
  } else {
    return <button {...props}>{props.children}</button>;
  }
};

export function RoundedButton({
  color = 'primary',
  size = 'medium',
  className,
  onClick,
  href,
  children,
  noButton = false,
}: RoundedButtonProps) {
  return (
    <RoundedButtonWrapper
      className={classnames(
        className,
        'flex flex-none items-center justify-center rounded-full focus:outline-none focus:ring',
        {
          'size-8 sm:size-10 md:size-12': size === 'medium',
          'size-4 sm:size-6 md:size-8': size === 'small',

          'bg-primary': color === 'primary',
          'bg-primary-100': color === 'bg-primary-100',
          'bg-success-800': color === 'success-800',
          'bg-neutral-300': color === 'grey',
        }
      )}
      href={href}
      onClick={onClick}
      noButton={noButton}
    >
      {children}
    </RoundedButtonWrapper>
  );
}
