import { ContentContainer } from '@jernia/shared/components/content-container/content-container';
import {
  FileRemoveIcon,
  HeadsetIcon,
  QuestionMarkCircleIcon,
} from '@jernia/shared/components/icons/icons';

import { useLoggedInEmployee } from '@jernia/shared/state/auth';

import { Logo } from '../logo/logo';

export default function Footer() {
  const employee = useLoggedInEmployee();

  return (
    <footer className="mt-auto bg-primary-800 px-4 py-8">
      <ContentContainer>
        <div className="flex justify-center py-8">
          <Logo />
        </div>

        <div className="mt-8 grid-cols-2 items-center border-t border-primary pt-8 md:grid">
          <div>
            <h4 className="font-semibold text-primary-500">
              {employee.data?.name}
            </h4>
            <div className="mt-2 text-xs text-white">
              <div>
                <span className="font-semibold">ID:</span> {employee.data?.uid}
              </div>
              <div>
                <span className="font-semibold">Butikk:</span>{' '}
                {employee.data?.store?.displayName}
              </div>
            </div>
          </div>

          <div className="mt-8 md:mt-0">
            <ul className="space-y-6 font-semibold text-white lg:flex lg:space-x-8 lg:space-y-0">
              <li>
                <a
                  className="flex hover:text-primary-500"
                  href="https://www.jernia.no/kundeservice"
                  target="_blank"
                  rel="noreferrer"
                >
                  <HeadsetIcon size={6} className="mr-3 shrink-0" />{' '}
                  Kundeservice
                </a>
              </li>
              <li>
                <a
                  className="flex hover:text-primary-500"
                  href="https://www.jernia.no/kundeservice#faq"
                  target="_blank"
                  rel="noreferrer"
                >
                  <QuestionMarkCircleIcon size={6} className="mr-3 shrink-0" />
                  Ofte stilte spørsmål
                </a>
              </li>
              <li>
                <a
                  className="flex hover:text-primary-500"
                  href="https://www.jernia.no/salgsbetingelser#angrerett"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FileRemoveIcon size={6} className="mr-3 shrink-0" />
                  Angrerett
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ContentContainer>
    </footer>
  );
}
