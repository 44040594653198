import classNames from 'classnames';
import React from 'react';

import { ArrowRightIcon } from '@jernia/shared/components/icons/icons';

import { JerniaLink } from '../jernia-link';

export type Breadcrumb = {
  name: string;
  url?: string;
  storefrontUrl?: string;
  onClick?: () => void;
  shallow?: boolean;
};

type Props = {
  breadcrumbs: Breadcrumb[];
  small?: boolean;
  wrap?: boolean;
  className?: string;
};

// @TODO: Use Next <Link /> conditionally

export function Breadcrumbs({ breadcrumbs, small, className, wrap }: Props) {
  return (
    <div className="overflow-x-auto">
      <ol
        className={classNames(
          'flex items-center text-sm font-semibold m-0 md:py-1',
          {
            'sm:text-hd-4 md:text-hd-2': !small,
            'sm:text-base': small,
          },
          {
            'flex-wrap': wrap,
          }
        )}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {breadcrumbs.map((breadcrumb, i) => {
          const last = i === breadcrumbs.length - 1;

          // Remove soft hyphen from name
          const name = breadcrumb.name.replace('&shy;', '');

          return (
            <li
              key={i}
              className="flex items-center"
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <>
                <meta itemProp="position" content={String(i + 1)} />

                {breadcrumb.url ? (
                  <JerniaLink
                    href={breadcrumb.url}
                    shallow={breadcrumb.shallow}
                    isNextLink={breadcrumb.shallow}
                  >
                    <a
                      className="whitespace-nowrap text-primary hover:text-text-800"
                      itemProp="item"
                    >
                      <span itemProp="name">{name}</span>
                    </a>
                  </JerniaLink>
                ) : breadcrumb.onClick ? (
                  <button
                    onClick={breadcrumb.onClick}
                    className="whitespace-nowrap text-primary hover:text-text-800"
                    itemProp="item"
                  >
                    <span itemProp="name">{name}</span>
                  </button>
                ) : (
                  <span
                    className={classNames('whitespace-nowrap', className)}
                    itemProp="name"
                  >
                    {name}
                  </span>
                )}

                {!last && (
                  <ArrowRightIcon size={5} className="mx-1 shrink-0 md:mx-2" />
                )}
              </>
            </li>
          );
        })}
      </ol>
    </div>
  );
}
