import { useEffect, useState } from 'react';

function useMatchMedia(query: string) {
  const [match, setMatch] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    setMatch(mediaQuery.matches);

    const handler = (event: MediaQueryListEvent) => {
      setMatch(event.matches);
    };

    mediaQuery.addEventListener('change', handler);

    return () => {
      mediaQuery.removeEventListener('change', handler);
    };
  }, [query]);

  return match;
}

type Breakpoint = 'sm' | 'md' | '2md' | 'lg' | 'xl' | '2xl';

const breakpointMap: Record<Breakpoint, string> = {
  sm: '640px',
  md: '768px',
  '2md': '992px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};

export function useBreakpoint(breakpoint: Breakpoint): boolean {
  const query = `(min-width: ${breakpointMap[breakpoint]})`;
  return useMatchMedia(query);
}
