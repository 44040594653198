import { differenceInDays, format, isToday } from 'date-fns';
import { useQuery } from 'react-query';

import { config } from '../lib/config';
import type { JerniaProduct } from '../types/product';

export function useProducts(productIds: string[]): JerniaProduct[] {
  const productCodes = productIds.join(',');

  const queryInfo = useQuery(
    ['products', ...productIds],
    () => {
      if (!productCodes) {
        return [];
      }

      if (config.site === 'jernia-no') {
        return fetch(`/product/data/multiple?codes=${productCodes}`).then(
          (resp) => resp.json()
        );
      }

      // @TODO: Don't proxy in production
      return fetch(`/api/products?codes=${productCodes}`).then((resp) =>
        resp.json()
      );
    },
    {
      retry: 0,
    }
  );

  return queryInfo.isSuccess ? queryInfo.data : [];
}

export function useProductQuery(productId?: string) {
  return useQuery<unknown, unknown, JerniaProduct | null>(
    ['product', productId],
    async () => {
      const response = await fetch(`/product/data/multiple?codes=${productId}`);
      if (!response.ok) {
        return null;
      }

      const result = await response.json();
      return result[0] ?? null;
    },
    {
      retry: 0,
      enabled: typeof productId === 'string',
    }
  );
}

export function useProduct(productId: string): JerniaProduct | null {
  const products = useProducts([productId]);
  return products[0] || null;
}
